import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"


export default function Index() {
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="I'm Jess Byrne, a fully qualified NLP Practitioner, certified in the
            Ollie Model of Transformative Coaching." />
        <title>Jess Byrne Coaching</title>
      </Helmet>
      <div className="container max-w-screen-md	 mx-auto text-stone-900		">
        <main className="mt-6 mx-auto px-4 sm:mt-6 md:mt-10 lg:mt-12">
          <div className="hidden sm:block rounded-full">
            <StaticImage placeholder="blurred" imgStyle={{ borderRadius: '100%' }}
              loading="eager" width={250} objectFit="contain" className=" sm:float-right shadow-lg shadow-emerald-100	 mb-8 rounded-full 	" src="../images/profile.jpg" alt="NLPEA Logo" />
          </div>
          <h1 className="text-3xl tracking-tight mb-8 font-bold text-gray-900 sm:text-5xl md:text-6xl text-center sm:text-left">

            <span className="xl:inline">Jess Byrne</span>
            <span className="text-emerald-600 xl:inline"> Coaching</span>
          </h1>
          <div className="flex items-center	content-center w-full rounded-full	flex-col mb-8 sm:hidden">
            <StaticImage placeholder="blurred"
              loading="eager" imgStyle={{ borderRadius: '100%' }} width={250} objectFit="contain" className="auto shadow-lg	 rounded-full sm:float-right	" src="../images/profile.jpg" alt="NLPEA Logo" />
          </div>

          <h2 className="text-4xl mb-4  font-bold">Who am I?</h2>

          <p className="mb-4 text-xl max-w-prose ">
            I'm Jess Byrne, a fully qualified NLP Practitioner, certified in the
            Ollie Model of Transformative Coaching.
          </p>
          <p className="mb-4 text-xl max-w-prose ">
            I started off in the legal profession but grew to realise that that didn't enable me to reach people and help them the way I wanted to, which I knew from a young age was really what I was meant to do.
          </p>
          <p className="mb-4 text-xl max-w-prose ">
            We've all felt stuck - myself included - but I know from experience that it is possible to move past things in our lives that keep us there and hold us back.
          </p>
          <p className="mb-4 text-xl max-w-prose ">
            Based in Greenwich, London, I offer coaching in my local area as well as online anywhere in the world.
          </p>
          <div className="bg-emerald-50 shadow-md	shadow-emerald-100	 rounded-lg	p-4 my-8">
            <h2 className="text-4xl mb-4 font-bold">What I do</h2>
            <p className="mb-4 text-xl">
              I'm here to empower you to make the changes you need to live the life you want. The things that hold you back in life - anxiety, depression, phobias, lack of confidence, limiting beliefs that aren't serving you – don't have to keep you stuck there. Sometimes we all need a bit of a "system upgrade".
            </p>
            <p className="mb-4 text-xl">
              I can work with you to help you move beyond these things, and quicker than you might think.
            </p>
            <p className="mb-4 text-xl">
              I provide non-judgmental, solution-focussed coaching to teenagers and adults.
            </p>
            <p className="mb-4 text-xl">
              Coaching is driven by you and will take place either face-to-face or online, for an average of 50 minutes per session.
            </p>
          </div>
        </main>
      </div>

      <div className="bg-emerald-800 py-8 text-white">
        <div className="container max-w-screen-md	 mx-auto  text-stone-100		">
          <div className="px-4 sm:flex flex-row block">
            <div>
              <h2 className="text-4xl mb-4 font-bold">Contact Me</h2>
              <p className="mb-4 text-lg sm:text-2xl">Please email me at <a className="font-bold" href="mailto:jess.byrne@ollieandhissuperpowers.com">jess.byrne@ollieandhissuperpowers.com</a> to book a free call.</p>
              <p className="mb-4 text-lg sm:text-2xl">Call me: <a href="tel:+447917300360" className="font-bold">07917 300360</a></p>
            </div>
            <div className="px-4 flex flex-row">
              <StaticImage objectFit="contain" src="../images/1.png" alt="NLPEA Logo" />
              <StaticImage objectFit="contain" src="../images/2.png" alt="NLPEA Logo" />
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}
